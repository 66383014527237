'use client';

import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import dotLoaderJson from '@/public/images/DotLoader.json';

const Lottie = dynamic(() => import('react-lottie-player'), { ssr: false });

export function Loader({ className }: { className?: string }): JSX.Element {
  return (
    <div
      className={`flex items-start justify-center min-h-screen pt-16 ${className}`}
    >
      <Lottie animationData={dotLoaderJson} loop play />
    </div>
  );
}

export function LoaderWithText({
  subtitle = 'Please do not close this window.',
  switchInterval = 4000,
  textItems,
}: {
  className?: string;
  textItems: string[];
  switchInterval?: number;
  subtitle?: string;
}): JSX.Element {
  const [stringIndex, setStringIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStringIndex(prevIndex =>
        prevIndex === textItems.length - 1 ? 0 : prevIndex + 1,
      );
    }, switchInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='card-background'>
      <div className='flex flex-col items-center w-full gap-4 px-8 py-4 justify-top grow lg:grow-0'>
        <p className='h-10 m-8 text-[20px] lg:text-[16px] text-center animate-[pulse_2s_ease-in-out_infinite] '>
          {textItems[stringIndex]}
        </p>
        <Lottie animationData={dotLoaderJson} loop play />
        <p className='m-8 text-[18px] lg:text-[14px] text-center text-[#817F8B]'>
          {subtitle}
        </p>
      </div>
    </div>
  );
}

export function MiniLoader() {
  return (
    <div className='flex items-center justify-center w-full h-full py-24'>
      <Lottie animationData={dotLoaderJson} loop play />
    </div>
  );
}
